<div class="w-full flex flex-col flex-grow justify-between items-center content-center">
    <div class="w-full flex flex-col gap-4">
        @for (item of menu(); track item) {
            <button mat-button
                    (click)="navigateTo(item.page); triggerMenu()"
                    class="flex-row align-start-center"
                    [class.active]="item.isHover || rla.isActive"
                    [routerLink]="item.url"
                    (mouseenter)="item.isHover = true"
                    (mouseleave)="item.isHover = false"
                    routerLinkActive
                    #rla="routerLinkActive">
                @if (item.icon) {
                    <mat-icon
                            [color]="rla.isActive || item.isHover ? '500' : undefined">{{item.icon}}</mat-icon>
                }
                <div class="two-lines-ellipsis word-break-word">
                    {{ item.tKey | translate }}
                </div>
            </button>
        }
    </div>

    <div class="w-full flex flex-col justify-start items-center content-center gap-4">
        @if (settings) {
            <button mat-button
                    [class.active]="settings.isHover || rla.isActive"
                    [routerLink]="settings.url"
                    (mouseenter)="settings.isHover = true"
                    (mouseleave)="settings.isHover = false"
                    class="first align-start-center flex-row"
                    routerLinkActive
                    #rla="routerLinkActive">
                <mat-icon class="item-margin-right"
                          [color]="rla.isActive || settings.isHover ? '500' : undefined">settings</mat-icon>
                {{ translations.settings.title | translate }}
            </button>
        }
        <button mat-button
                [class.active]="itemHover['logoutHover']"
                (mouseenter)="itemHover['logoutHover'] = true"
                (mouseleave)="itemHover['logoutHover'] = false"
                routerLink="login"
                (click)="appNavigationService.logout(true); triggerMenu()"
                class="last align-start-center flex-row">
            <mat-icon class="item-margin-right"
                      [color]="itemHover['logoutHover'] ? '500' : undefined">logout</mat-icon>
            {{ translations.global.logout | translate }}
        </button>
    </div>
</div>
