import { inject, Injectable } from '@angular/core';
import { AppStatusBarService } from '@shared/services/app-status-bar.service';
import { AppSafeAreaInsetService } from '@shared/services/app-safe-area-inset.service';
import { AppOrientationService } from '@shared/services/app-orientation.service';
import { AppLanguageService } from '@shared/services/app-language.service';
import { AppNetworkService } from '@shared/services/app-network.service';
import { AppService } from '@shared/services/app.service';
import { UserService } from '@shared/services/user.service';
import {environment} from "@env/environment.prod";

@Injectable({
	providedIn: 'root',
})
export class AppStarterService {
	private appStatusBarService: AppStatusBarService = inject(AppStatusBarService);
	private appSafeAreaInsetService: AppSafeAreaInsetService = inject(AppSafeAreaInsetService);
	private appOrientationService: AppOrientationService = inject(AppOrientationService);
	private appLanguageService: AppLanguageService = inject(AppLanguageService);
	private appNetworkService: AppNetworkService = inject(AppNetworkService);

	private userService: UserService = inject(UserService);

	isNativePlatform = inject(AppService).isNativePlatform;

	async initServices(): Promise<boolean> {
		let accessTokenFromLocalStorage = localStorage.getItem('lb.accessToken');
		if(accessTokenFromLocalStorage){
			this.userService.accessToken.set(JSON.parse(accessTokenFromLocalStorage));
		}

		this.appLanguageService.fnInit();
		this.appNetworkService.fnInit();

		if (this.isNativePlatform()) {
			this.appStatusBarService.fnInit();
			this.appSafeAreaInsetService.fnInit();
			this.appOrientationService.lockPortrait();
		}

		const userActive: boolean = await this.userService.setUserData();
		return !userActive;
	}

	refreshServices(): void {
		if (this.isNativePlatform()) {
			this.appStatusBarService.fnInit();
			this.appSafeAreaInsetService.fnInit();
			this.appOrientationService.lockPortrait();
		}
	}
}
