import { Directive, ElementRef, Renderer2, inject, effect } from '@angular/core';
import { AppService } from '@shared/services/app.service';

@Directive({
	standalone: true,
	selector: '[sideMenuOpen]',
})
export class SideMenuOpenDirective {
	private el: ElementRef = inject(ElementRef);
	private renderer: Renderer2 = inject(Renderer2);

	sideMenuOpen = inject(AppService).sideMenuOpen;

	constructor() {
		effect(() => {
			if (this.sideMenuOpen()) this.renderer.addClass(this.el.nativeElement, 'side-menu-open');
			else this.renderer.removeClass(this.el.nativeElement, 'side-menu-open');
		});
	}
}
