import {Component, computed, inject, signal, Signal, WritableSignal} from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { translations } from '@shared/utils/translations';
import { MatButton } from '@angular/material/button';
import { AppNetworkService } from '@shared/services/app-network.service';
import { AppService } from '@shared/services/app.service';
import { AppStarterService } from '@shared/services/app-starter.service';
import { SplashScreen } from '@capacitor/splash-screen';
import { AppStatusBarService } from '@shared/services/app-status-bar.service';
import { AppNavigationService } from '@shared/services/app-navigation.service';
import { UserService } from '@shared/services/user.service';
import { RouterOutlet } from '@angular/router';
import { MenuComponent } from '@shared/components/menu';
import { IsMobileDirective } from '@shared/directives/is-mobile.directive';
import { IsLoggedInDirective } from '@shared/directives/is-logged-in.directive';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { styles } from '@shared/services/app-styles.service';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import {MatIcon} from "@angular/material/icon";
import {environment} from "@env/environment";
import {MenuHeaderComponent} from "@shared/components/menu-header";
import {config} from "@app/config/config";
import {configMenuOrientation} from "@app/config/config.enum";
import {SideMenuOpenDirective} from "@shared/directives/side-menu-open.directive";
import localeDe from '@angular/common/locales/de';
import {registerLocaleData} from "@angular/common";
import {LoaderComponent} from "@interceptor/loader/loader/loader.component";

@Component({
	selector: 'app-root',
	standalone: true,
	imports: [
		TranslateModule,
		MatMenuTrigger,
		MatMenuItem,
		MatMenu,
		MatButton,
		RouterOutlet,
		MenuComponent,
		MatProgressSpinner,
		MatIcon,
		MenuHeaderComponent,
		SideMenuOpenDirective,
		IsMobileDirective,
		LoaderComponent,
	],
	hostDirectives: [IsMobileDirective, IsLoggedInDirective, SideMenuOpenDirective],
	templateUrl:'app.component.html',
	styleUrl: 'app.component.scss',
	animations: [
		trigger('fade', [
			state('in', style({ opacity: 1 })),
			transition(':enter', [style({ opacity: 0 }), animate(styles.themeDurationSlow)]),
			transition(':leave', [animate(styles.themeDurationSlow, style({ opacity: 0 }))]),
		]),
	],
})
export class AppComponent {
	protected readonly translations = translations;
	public translate: TranslateService = inject(TranslateService);
	private appStarterService: AppStarterService = inject(AppStarterService);
	private appStatusBarService: AppStatusBarService = inject(AppStatusBarService);
	private appNavigationService: AppNavigationService = inject(AppNavigationService);
	private userService: UserService = inject(UserService);
	public appService: AppService = inject(AppService);

	hasConnection: Signal<boolean> = inject(AppNetworkService).hasConnection;
	isLoggedIn: Signal<boolean> = this.userService.isLoggedIn;
	loading: Signal<boolean> = this.appService.loading;
	isNativePlatform: Signal<boolean> = this.appService.isNativePlatform;
	isDev = computed(() => environment.production);

	userLoggedIn: Signal<boolean> = computed(() => this.isLoggedIn() && !this.loading());

	constructor() {
		registerLocaleData(localeDe, 'de-DE');

		this.appStarterService.initServices().then(async hasError => {
			if (hasError) await this.appNavigationService.logout();

			await new Promise(r => setTimeout(r, 300)); // smooth experience
			this.appService.stopLoading();

			await new Promise(r => setTimeout(r, 200)); // smooth experience
			await SplashScreen.hide();
			await new Promise(r => setTimeout(r, 100));
			if (this.isNativePlatform()) {
				this.appStatusBarService.setOverlaysWebView();
			}
		});
	}

	protected readonly environment = environment;
	protected readonly config = config;
	protected readonly configMenuOrientation = configMenuOrientation;
}
