import { Component, computed, inject, Signal } from '@angular/core';
import { MatBadge } from '@angular/material/badge';
import { MatButton } from '@angular/material/button';
import { MatDivider } from '@angular/material/divider';
import { MatIcon } from '@angular/material/icon';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { IMenu } from '@shared/components/menu/menu.interface';
import { IsMobileDirective } from '@shared/directives/is-mobile.directive';
import { IRouteAccessConfig, TPageType } from '@shared/interfaces/route.interface';
import { UserModel } from '@shared/models';
import { AppNavigationService } from '@shared/services/app-navigation.service';
import { AppRouteService } from '@shared/services/app-route.service';
import { AppStyles } from '@shared/services/app-styles.service';
import { AppService } from '@shared/services/app.service';
import { UserService } from '@shared/services/user.service';
import { translations } from '@shared/utils/translations';

@Component({
    selector: 'app-menu',
    standalone: true,
    templateUrl: 'menu.component.html',
    styleUrl: 'menu.component.scss',
    imports: [TranslateModule, RouterLink, RouterLinkActive, MatBadge, MatDivider, MatIcon, MatButton],
    hostDirectives: [IsMobileDirective],
})
export class MenuComponent {
    protected readonly translations = translations;
    private appRouteService: AppRouteService = inject(AppRouteService);
    private userService: UserService = inject(UserService);
    private appStyles: AppStyles = inject(AppStyles);
    appService: AppService = inject(AppService);
    appNavigationService: AppNavigationService = inject(AppNavigationService);

    isMobile = inject(AppService).isMobile;
    user = inject(UserService).user;
    styles = this.appStyles.styles;

    settings: IMenu | undefined = this.getMenuItem('settings');
    itemHover: { [key: string]: boolean } = {};
    eventListener: any;

    navigateTo = (page: TPageType) => this.appNavigationService.navigateTo(page, { from: 'menu' });

    menu: Signal<IMenu[]> = computed(() => {
        const user = this.user();
        const menu: IMenu[] = [];

        for (const route in this.appRouteService.routeAccess) {
            const pageType: TPageType = route as TPageType;
            if (pageType === 'settings') continue;
            if (pageType === 'profile') continue;
            const item = this.getMenuItem(pageType, user);
            if (item) menu.push(item);
        }

        return menu;
    });

    constructor() {}

    getMenuItem(pageType: TPageType, user?: UserModel) {
        const config: IRouteAccessConfig<any> = this.appRouteService.routeAccess[pageType];
        const routeAvailable: boolean = this.appRouteService.getRouteAvailability(
            config,
            user ?? this.userService.user(),
        );

        // main path
        if (routeAvailable) {
            return {
                page: pageType,
                tKey: config.title ?? '',
                url: this.appRouteService.getPath(pageType),
                roles: config.roles,
                icon: config.icon,
                notifications: '',
            };
        }
        return undefined;
    }

    triggerMenu() {
        if (!this.isMobile()) return;

        this.appService.triggerMenu();
        if (this.eventListener) {
            document.removeEventListener('click', this.eventListener);
            this.eventListener = null;
        }
    }
}
