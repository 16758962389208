<app-loader></app-loader>

@if (!hasConnection()) {
    <div class="connection-failed">
        <mat-icon color="white" class="item-margin-right">wifi_off</mat-icon>
        {{ translations.global.no_internet_connection | translate }}
    </div>
}

@if (loading()) {
    <div class="spinner spinnerContainer">
        <span class="loader"></span>
    </div>
} @else {
    @if (config.menuOrientation == configMenuOrientation.side) {
        <div class="flex flex-col flex-grow width-100">
            @if (userLoggedIn()) {
                <app-menu-header @fade />
            }
            <div class="flex flex-grow flex-row w-full mainFrame" @fade>
                @if (appService.sideMenuOpen()){
                    <app-menu></app-menu>
                }
                <div class="flex flex-grow side-content" sideMenuOpen isMobile @fade>
                    <router-outlet />
                </div>
            </div>
        </div>
    } @else if(config.menuOrientation == configMenuOrientation.top) {
        <div class="flex flex-col flex-grow width-100">
            @if (userLoggedIn()) {
                <app-menu-header @fade />
            }
            <div class="flex flex-grow w-full default-padding" @fade>
                <router-outlet />
            </div>
        </div>
    }

}

<div class="indicator-section flex flex-row gap-2 justify-start items-center content-center">
    @if(!isDev()){
        <div class="prod-indicator flex flex-row justify-center content-center items-center">
            <span>{{ translations.global.isDev | translate }}</span>
        </div>
    }
    <div class="flex flex-col justify-center content-center items-center version-indicator">v{{environment.version}}</div>
</div>

