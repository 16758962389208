import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderService } from '../loader.service';
import {MatProgressSpinner} from "@angular/material/progress-spinner";

interface loaderStorage {
    element: HTMLDivElement;
    visible: boolean;
}

@Component({
    selector: 'app-loader',
    template: '',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [MatProgressSpinner]
})
export class LoaderComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];
    private storage: loaderStorage;

    constructor(private loaderService: LoaderService) {
        this.storage = {
            element: document.createElement('div'),
            visible: false,
        };

        this.storage.element.className = 'spinner-container hidden';
        document.body.appendChild(this.storage.element);

        let spinnerEl = document.createElement('div');
        spinnerEl.className = 'spinner spinnerContainer';
        spinnerEl.innerHTML =
            '<span class="loader"></span>';

        this.storage.element.appendChild(spinnerEl);
    }

    ngOnInit() {
        this.subscriptions.push(
            this.loaderService.loaderSubject.subscribe(isLoading => {
                setTimeout(() => {
                    this.storage.visible = isLoading;
                    if (isLoading) {
                        this.storage.element.classList.remove('hidden');
                    } else {
                        this.storage.element.classList.add('hidden');
                    }
                });
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => {
            sub.unsubscribe();
        });
        setTimeout(() => {
            document.body.removeChild(this.storage.element);
        });
    }
}
