<app-loader></app-loader>

@if (!hasConnection()) {
    <div class="connection-failed">
        <mat-icon color="white" class="item-margin-right">wifi_off</mat-icon>
        {{ translations.global.no_internet_connection | translate }}
    </div>
}

@if (loading()) {
    <div class="spinner spinnerContainer">
        <span class="loader"></span>
    </div>
} @else {
    @if (config.menuOrientation == configMenuOrientation.side) {
        <div class="width-100 flex flex-grow flex-col">
            @if (userLoggedIn()) {
                <app-menu-header @fade />
            }
            <div class="mainFrame flex w-full flex-grow flex-row" @fade>
                @if (appService.sideMenuOpen()) {
                    <app-menu></app-menu>
                }
                <div class="side-content flex flex-grow" sideMenuOpen isMobile @fade>
                    <router-outlet />
                </div>
            </div>
        </div>
    } @else if (config.menuOrientation == configMenuOrientation.top) {
        <div class="width-100 flex flex-grow flex-col">
            @if (userLoggedIn()) {
                <app-menu-header @fade />
            }
            <div class="default-padding flex w-full flex-grow" @fade>
                <router-outlet />
            </div>
        </div>
    }
}

<div class="indicator-section flex flex-row content-center items-center justify-start gap-2">
    @if (!isDev()) {
        <div class="prod-indicator flex flex-row content-center items-center justify-center">
            <span>{{ translations.global.isDev | translate }}</span>
        </div>
    }
    <div class="version-indicator flex flex-col content-center items-center justify-center">
        v{{ environment.version }}
    </div>
</div>
